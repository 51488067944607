
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { createGroup, getGroupInfo, getGroups, MEMBERS_ROLES } from 'Store/v2/Groups';
import Button from 'Control/Button.vue';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import theme from 'Theme';
import Icon from 'UI/Icon.vue';
import Guid from 'UI/Guid.vue';
import Group from 'Entities/privatePresenter/Group';
import GroupUser from 'Entities/privatePresenter/GroupUser';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';

interface Data {
    theme: any;
}

interface Methods {
    createGroup: () => void;
    getAdminUsers: (data: GroupUser[]) => string;
    manageGroup: (data: Group) => void;
}

interface Computed {
    groups: Group[];
    hasOwnGroup: boolean;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Button,
        Icon,
        Guid,
    },
    data() {
        return {
            theme,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        groups() {
            return this.$store.state.Groups.groups;
        },
        hasOwnGroup() {
            return this.groups.some(({ members }) => members?.some(({ uid, roleName }) => uid === this.currentUser?.id && roleName === MEMBERS_ROLES.creator));
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            return this.currentUser?.kycStatus.toLowerCase() === 'verified';
        },
    },
    methods: {
        async createGroup() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(createGroup(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error creating a group');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', error);
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        getAdminUsers(members) {
            const users = members.length;
            // eslint-disable-next-line camelcase
            const admins = members.reduce((accum, { roleName }) => (roleName !== MEMBERS_ROLES.member ? accum + 1 : accum), 0);
            return `${admins} / ${users}`;
        },
        async manageGroup(group) {
            try {
                await this.$store.dispatch(getGroupInfo(group.id));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during open group management page');
                }
            }
        },
    },
    async mounted() {
        await this.$store.dispatch(getGroups(undefined));
    },
});
